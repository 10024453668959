import { Box } from '@mantine/core';

import FormSide from './components/FormSide';
import InvoiceSide from './components/InvoiceSide';

const Dashboard = () => {
  return (
    <Box
      sx={(theme) => ({
        height: '100%',
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))"
      })}
    >
      <FormSide />
      <InvoiceSide />
    </Box>
  );
};

export default Dashboard;
