import {
  Accordion,
  ActionIcon,
  Anchor,
  Autocomplete,
  Box,
  Button,
  createStyles,
  Divider,
  Group,
  Loader,
  NumberInput,
  ScrollArea,
  Select,
  SimpleGrid,
  Stack,
  Table,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  ChevronDown,
  ChevronUp,
  FileDownload,
  Minus,
  Percentage,
} from 'tabler-icons-react';
import CreateClientModal, { CreateClientModalProps } from './CreateClientModal';
import { useModals } from '@mantine/modals';
import React, { useContext, useEffect, useState } from 'react';
import { MadmanContext } from '../../../UTILS/MadmanProvider';
import useStateEffect from '../../../UTILS/hooks/useStateEffect';
import {
  DeepPartial,
  DMClient,
  DMInvoice,
  DMInvoiceField,
} from '../../../UTILS/hooks/Types';
import { find } from 'lodash';
import {
  arrayUnion,
  collection,
  doc,
  DocumentReference,
  getFirestore,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { DatePicker } from '@mantine/dates';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { createOCR, isTimestamp } from '../../../UTILS/Functions';

const useStyles = createStyles((theme, _params, getRef) => ({
  main: {
    display: 'grid',
    gridTemplateRows: 'auto max-content',
    height: '100%',
  },
}));

const CreateInvoice = ({
  setCreateInvoiceKey,
}: {
  setCreateInvoiceKey: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { classes } = useStyles();
  const modals = useModals();
  const { clients, setWipInvoice, wipInvoice, ourInvoiceInfo } =
    useContext(MadmanContext);

  const form = useForm({
    initialValues: {
      client:
        wipInvoice?.client instanceof DocumentReference
          ? wipInvoice?.client.path
          : '',
      reference: wipInvoice?.info?.client_reference || '',
      invoice_number: wipInvoice?.info?.invoice_number || 1,
      invoice_date: isTimestamp(wipInvoice?.invoice_date)
        ? wipInvoice!.invoice_date.toDate()
        : new Date(),
      due_date: isTimestamp(wipInvoice?.due_date)
        ? wipInvoice!.due_date.toDate()
        : moment().add(14, 'days').toDate(),
      interest: wipInvoice?.info?.interest || 0,
      payment_terms: wipInvoice?.info?.payment_terms || '',
      delivery_terms: wipInvoice?.info?.delivery_terms || '',
      delivery_method: wipInvoice?.info?.delivery_method || '',
      service_fee: wipInvoice?.service_fee || 0,
      our_reference:
        wipInvoice?.info?.our_reference || ourInvoiceInfo?.reference || '',
      our_name: wipInvoice?.info?.our_info?.name || ourInvoiceInfo?.name || '',
      our_org_id:
        wipInvoice?.info?.our_info?.org_id || ourInvoiceInfo?.org_id || '',
      our_address_street:
        wipInvoice?.info?.our_info?.address?.street ||
        ourInvoiceInfo?.address.street ||
        '',
      our_address_number:
        wipInvoice?.info?.our_info?.address?.number ||
        ourInvoiceInfo?.address.number ||
        '',
      our_address_zip_code:
        wipInvoice?.info?.our_info?.address?.zip_code ||
        ourInvoiceInfo?.address.zip_code ||
        '',
      our_address_city:
        wipInvoice?.info?.our_info?.address?.city ||
        ourInvoiceInfo?.address.city ||
        '',
    },

    validate: {
      client: (value) => (value?.length ? null : 'Välj en kund'),
      reference: (value) => (value?.length ? null : 'Välj en referens'),
      invoice_number: () => null,
    },
  });

  const [selectedClient, setSelectedClient] = useStateEffect<
    DMClient | undefined
  >(
    undefined,
    () => {
      const tempClient = find(
        clients,
        (e) => e.ref.path === form.values.client
      );
      if (tempClient?.ref.path === selectedClient?.ref.path) return;
      setSelectedClient(tempClient);
      if (!form.values.client) {
        form.reset();
      }
    },
    [clients, form.values.client]
  );

  const [loadingInvoiceNumber, setLoadingInvoiceNumber] = useStateEffect(
    false,
    () => {
      if (!selectedClient) return;
      setLoadingInvoiceNumber(true);
      const listener = onSnapshot(
        query(
          collection(getFirestore(), selectedClient.ref.path, 'invoices'),
          orderBy('info.invoice_number', 'asc'),
          limitToLast(1)
        ),
        (snapshot) => {
          setLoadingInvoiceNumber(false);
          if (!snapshot.empty) {
            const lastInvoiceNumber = (snapshot.docs[0].data() as DMInvoice)
              .info.invoice_number;

            if (!wipInvoice?.ref) {
              form.setFieldValue('invoice_number', lastInvoiceNumber + 1);
            }
          }
        }
      );
      return () => listener();
    },
    [selectedClient]
  );

  useEffect(() => {
    setWipInvoice((p) => ({
      ...p,
      client: selectedClient?.ref,
      invoice_date: Timestamp.fromDate(form.values.invoice_date),
      due_date: Timestamp.fromDate(form.values.due_date),
      info: {
        our_reference: form.values.our_reference,
        our_info: {
          name: form.values.our_name,
          org_id: form.values.our_org_id,
          address: {
            street: form.values.our_address_street,
            number: form.values.our_address_number,
            zip_code: form.values.our_address_zip_code,
            city: form.values.our_address_city,
          },
        },
        client_reference: form.values.reference,
        interest: form.values.interest,
        payment_terms: form.values.payment_terms,
        delivery_terms: form.values.delivery_terms,
        delivery_method: form.values.delivery_method,
        client_number: selectedClient?.client_number,
        invoice_number: form.values.invoice_number,
      },
      service_fee: form.values.service_fee,
    }));
  }, [form.values, selectedClient]);

  const [loadingSave, setLoadingSave] = useState(false);
  const saveChanges = async () => {
    if (!selectedClient) return;
    const ocr = createOCR(
      wipInvoice?.info?.client_number,
      wipInvoice?.info?.invoice_number
    );
    if (ocr.length !== 10) return;
    if (loadingSave) return;
    setLoadingSave(true);

    const params: DeepPartial<DMInvoice> = {
      ...wipInvoice,
      client: selectedClient.ref,
      ocr: ocr,
    };

    const ref = doc(getFirestore(), selectedClient.ref.path, 'invoices', ocr);

    await setDoc(ref, params).catch((err) => {
      console.error(err);
    });

    setLoadingSave(false);

    window.requestAnimationFrame(() => {

        form.setFieldValue(
          'invoice_number',
          wipInvoice?.info?.invoice_number || 1
        );
    })
    setTimeout(() => {
    }, 150);
  };

  return (
    <Box className={classes.main}>
      <ScrollArea
        style={{
          height: '100%',
          transform: 'translate3d(0,0,0)',
        }}
      >
        <form>
          <Stack px="sm" py="lg" spacing={6}>
            <SimpleGrid cols={3}>
              <Select
                required
                label="Kund"
                placeholder="Välj en kund från listan"
                searchable
                creatable
                clearable
                shouldCreate={() => true}
                getCreateLabel={() => '+ Skapa ny kund'}
                data={clients.map((client) => ({
                  label: `${client.name} (knr: ${client.client_number})`,
                  value: client.ref.path,
                }))}
                onCreate={() => {
                  const id = modals.openModal({
                    ...CreateClientModalProps,
                    children: (
                      <CreateClientModal
                        onClose={() => modals.closeModal(id)}
                      />
                    ),
                  });
                }}
                {...form.getInputProps('client')}
              />
              <Select
                required
                label="Referens"
                placeholder="Välj en referens"
                searchable
                creatable
                getCreateLabel={(query) => '+ Skapa ' + query}
                data={(selectedClient?.reference || []).map((e) => ({
                  value: e,
                  label: e,
                }))}
                disabled={selectedClient ? false : true}
                onCreate={(query) => {
                  if (!selectedClient) return;
                  updateDoc(selectedClient.ref, {
                    reference: arrayUnion(query),
                  });
                }}
                {...form.getInputProps('reference')}
              />
              <NumberInput
                required
                label="Ordernummer"
                disabled={selectedClient ? false : true}
                min={1}
                rightSection={
                  loadingInvoiceNumber ? <Loader size="xs" /> : undefined
                }
                {...form.getInputProps('invoice_number')}
              />
            </SimpleGrid>
            <FieldTable />
            <Divider label="Övrigt" mt="xl" />
            <Accordion>
              <Accordion.Item label="Fakturainformation">
                <SimpleGrid cols={3}>
                  <DatePicker
                    required
                    label="Fakturadatum"
                    placeholder="ÅÅÅÅ-MM-DD"
                    locale="sv"
                    inputFormat="YYYY-MM-DD"
                    clearable={false}
                    {...form.getInputProps('invoice_date')}
                  />
                  <DatePicker
                    required
                    label="Förfallodatum"
                    placeholder="ÅÅÅÅ-MM-DD"
                    locale="sv"
                    inputFormat="YYYY-MM-DD"
                    clearable={false}
                    {...form.getInputProps('due_date')}
                  />
                  <NumberInput
                    label="Dröjsmålsränta"
                    icon={<Percentage size={18} />}
                    min={0}
                    placeholder="0"
                    precision={1}
                    step={0.1}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    decimalSeparator=","
                    {...form.getInputProps('interest')}
                  />
                  <TextInput
                    label="Betalningsvillkor"
                    placeholder="Aa..."
                    {...form.getInputProps('payment_terms')}
                  />
                  <TextInput
                    label="Leveransvillkor"
                    placeholder="Aa..."
                    {...form.getInputProps('delivery_terms')}
                  />
                  <TextInput
                    label="Leveranssätt"
                    placeholder="Aa..."
                    {...form.getInputProps('delivery_method')}
                  />
                  <NumberInput
                    label="Expeditionsavgift"
                    placeholder="0"
                    precision={2}
                    decimalSeparator=","
                    {...form.getInputProps('service_fee')}
                  />
                </SimpleGrid>
              </Accordion.Item>
              <Accordion.Item label="Vår information">
                <SimpleGrid cols={3}>
                  <TextInput
                    label="Referens"
                    placeholder="Aa..."
                    {...form.getInputProps('our_reference')}
                  />
                  <TextInput
                    label="Namn"
                    placeholder="Aa..."
                    {...form.getInputProps('our_name')}
                  />
                  <TextInput
                    label="Organisationsnummer"
                    placeholder="123456-7890"
                    {...form.getInputProps('our_org_id')}
                  />
                  <TextInput
                    label="Gata"
                    placeholder="Vägvägen"
                    {...form.getInputProps('our_address_street')}
                  />
                  <TextInput
                    label="Gatunummer"
                    placeholder="1"
                    {...form.getInputProps('our_address_number')}
                  />
                  <TextInput
                    label="Postnummer"
                    placeholder="123 45"
                    {...form.getInputProps('our_address_zip_code')}
                  />
                  <TextInput
                    label="Stad"
                    placeholder="Stadia"
                    {...form.getInputProps('our_address_city')}
                  />
                </SimpleGrid>
              </Accordion.Item>
            </Accordion>
          </Stack>
        </form>
      </ScrollArea>
      <Stack spacing={0}>
        <Divider />
        <Group position="apart" p="sm">
          <Button
            variant="light"
            color="red"
            onClick={() => {
              setCreateInvoiceKey(uuid());
              setWipInvoice(undefined);
            }}
          >
            Påbörja ny
          </Button>
          <Group position="right">
            <Button
              variant="default"
              loading={loadingSave}
              onClick={saveChanges}
            >
              Spara ändringar
            </Button>
            <Button
              leftIcon={<FileDownload size={14} />}
              onClick={() => window.print()}
            >
              Spara som PDF
            </Button>
          </Group>
        </Group>
      </Stack>
    </Box>
  );
};

export default CreateInvoice;

const FieldTable = () => {
  const { setWipInvoice, wipInvoice } = useContext(MadmanContext);

  const emptyField: DMInvoiceField = {
    title: '',
    amount: 0,
    unit: 'h',
    price: 750,
    total: 0,
    vat: 25,
    key: uuid(),
  };
  const [fields, setFields] = useState<DMInvoiceField[]>(
    (wipInvoice?.fields as DMInvoiceField[]) || [{ ...emptyField }]
  );

  useEffect(() => {
    setWipInvoice((p) => ({
      ...p,
      fields,
    }));
  }, [fields]);

  return (
    <Table mt="md">
      <thead>
        <tr>
          <th
            style={{
              width: 32,
            }}
          ></th>
          <th>Beskrivning</th>
          <th>Antal</th>
          <th>Enhet</th>
          <th>Pris</th>
          <th>Moms</th>
          <th
            style={{
              width: 32,
            }}
          ></th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field, index) => {
          return (
            <FieldRow
              key={field.key!}
              field={field}
              setFields={setFields}
              index={index}
              emptyField={emptyField}
            />
          );
        })}
        <tr>
          <td></td>
          <td>
            <Anchor
              size="sm"
              onClick={() => {
                setFields((p) => [...p, { ...emptyField }]);
              }}
            >
              + Lägg till rad
            </Anchor>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

const FieldRow = ({
  field,
  setFields,
  index,
  emptyField,
}: {
  field: DMInvoiceField;
  setFields: React.Dispatch<React.SetStateAction<DMInvoiceField[]>>;
  index: number;
  emptyField: DMInvoiceField;
}) => {
  return (
    <tr>
      <td>
        <Stack spacing={0}>
          <ActionIcon
            sx={(theme) => ({
              height: 14,
              minHeight: 14,

              '&:hover': {
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[5]
                    : theme.colors.gray[1],
              },
            })}
            onClick={() => {
              setFields((p) => {
                let copy = [...p];
                let srcI = index;
                let destI = index - 1;
                if (destI < 0) destI = 0;

                copy.splice(destI, 0, copy.splice(srcI, 1)[0]);
                return copy;
              });
            }}
          >
            <ChevronUp size={14} />
          </ActionIcon>
          <ActionIcon
            sx={(theme) => ({
              height: 14,
              minHeight: 14,

              '&:hover': {
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[5]
                    : theme.colors.gray[1],
              },
            })}
            onClick={() => {
              setFields((p) => {
                let copy = [...p];
                let srcI = index;
                let destI = index + 1;
                if (destI > copy.length - 1) destI = copy.length - 1;

                copy.splice(destI, 0, copy.splice(srcI, 1)[0]);
                return copy;
              });
            }}
          >
            <ChevronDown size={14} />
          </ActionIcon>
        </Stack>
      </td>
      <td>
        <TextInput
          variant="filled"
          placeholder="Aa..."
          maxLength={120}
          value={field.title}
          onChange={(e) => {
            setFields((p) => {
              let copy = [...p];
              copy[index].title = e.target.value;
              return copy;
            });
          }}
        />
      </td>
      <td
        style={{
          width: 120,
        }}
      >
        <NumberInput
          variant="filled"
          placeholder="0"
          precision={2}
          step={field.unit === 'h' ? 0.01 : 0.1}
          value={field.amount}
          stepHoldDelay={500}
          stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
          decimalSeparator=","
          onChange={(e) => {
            setFields((p) => {
              let copy = [...p];
              copy[index].amount = e || 0;
              return copy;
            });
          }}
        />
      </td>
      <td
        style={{
          width: 80,
        }}
      >
        <Select
          variant="filled"
          placeholder="h"
          value={field.unit}
          onChange={(e) => {
            setFields((p) => {
              let copy = [...p];
              copy[index].unit = e as 'h' | 'st';
              return copy;
            });
          }}
          data={[
            {
              label: 'h',
              value: 'h',
            },
            {
              label: 'st',
              value: 'st',
            },
          ]}
        />
      </td>
      <td
        style={{
          width: 100,
        }}
      >
        <Autocomplete
          variant="filled"
          placeholder="pris/enhet"
          value={field.price.toString()}
          onChange={(e) => {
            setFields((p) => {
              let copy = [...p];
              copy[index].price = isNaN(parseInt(e)) ? 0 : parseInt(e);
              return copy;
            });
          }}
          data={[
            {
              label: '550kr',
              value: '550',
            },
            {
              label: '750kr',
              value: '750',
            },
          ]}
        />
      </td>
      <td
        style={{
          width: 100,
        }}
      >
        <Select
          variant="filled"
          placeholder="x%"
          value={field.vat.toString()}
          onChange={(e) => {
            setFields((p) => {
              let copy = [...p];
              let value = parseInt(e || '');
              copy[index].vat = value as 25 | 12 | 6 | 0;
              return copy;
            });
          }}
          data={[
            {
              label: '25%',
              value: '25',
            },
            {
              label: '12%',
              value: '12',
            },
            {
              label: '6%',
              value: '6',
            },
            {
              label: '0%',
              value: '0',
            },
          ]}
        />
      </td>
      <td>
        <ActionIcon
          sx={(theme) => ({
            '&:hover': {
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[5]
                  : theme.colors.gray[1],
            },
          })}
          onClick={() => {
            setFields((p) => {
              let copy = [...p];
              let srcI = index;

              copy.splice(srcI, 1);
              if (copy.length === 0) {
                copy = [{ ...emptyField }];
              }
              return copy;
            });
          }}
        >
          <Minus size={14} />
        </ActionIcon>
      </td>
    </tr>
  );
};
