import {
  Button,
  Group,
  ModalProps,
  NumberInput,
  SimpleGrid,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { useContext, useState } from 'react';
import { DMClient } from '../../../UTILS/hooks/Types';
import { MadmanContext } from '../../../UTILS/MadmanProvider';

const CreateClientModal = ({ onClose }: { onClose: () => void }) => {
  const { clients } = useContext(MadmanContext);

  const form = useForm({
    initialValues: {
      name: '',
      org_id: '',
      address_street: '',
      address_number: '',
      address_zip_code: '',
      address_city: '',
      client_number: (clients[clients.length]?.client_number || 0) + 1,
    },

    validate: {
      name: (value) => (value.length ? null : 'Ogiltigt värde'),
      org_id: (value) => (value.length ? null : 'Ogiltigt värde'),
      address_street: (value) => (value.length ? null : 'Ogiltigt värde'),
      address_number: (value) => (value.length ? null : 'Ogiltigt värde'),
      address_zip_code: (value) => (value.length ? null : 'Ogiltigt värde'),
      address_city: (value) => (value.length ? null : 'Ogiltigt värde'),
      client_number: (value) => {
        let result: null | string = null;
        clients.forEach((client) => {
          if (client.client_number === value) result = 'Kundnumret finns redan';
        });
        return result;
      },
    },
  });

  const [loading, setLoading] = useState(false);
  const submit = async (values: {
    name: string;
    org_id: string;
    address_street: string;
    address_number: string;
    address_zip_code: string;
    address_city: string;
    client_number: number;
  }) => {
    if (loading) return;
    setLoading(true);

    const params: Omit<DMClient, 'ref'> = {
      name: values.name,
      org_id: values.org_id,
      address: {
        street: values.address_street,
        number: values.address_number,
        zip_code: values.address_zip_code,
        city: values.address_city,
      },
      client_number: values.client_number,
      reference: [],
    };

    await addDoc(collection(getFirestore(), 'clients'), params)
      .then(() => onClose())
      .catch((err) => console.error(err));

    setLoading(false);
  };

  return (
    <>
      <form onSubmit={form.onSubmit(submit)}>
        <Stack>
          <TextInput
            label="Namn"
            placeholder="Nånting AB"
            required
            data-autofocus
            {...form.getInputProps('name')}
          />
          <TextInput
            label="Organisationsnummer"
            placeholder="123456-7890"
            required
            {...form.getInputProps('org_id')}
            onChange={(e) => {
              const value = e.target.value;
              const numericValue = value.replace(/\D/g, '');

              if (numericValue.length < 7) {
                form.setFieldValue('org_id', value);
                return;
              }

              form.setFieldValue(
                'org_id',
                `${numericValue.substring(0, 6)}-${numericValue.substring(6, 10)}`
              );

              const caret = e.target.selectionStart;
              const element = e.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret === 7 ? caret + 1 : caret;
                element.selectionEnd = caret === 7 ? caret + 1 : caret;
              });
            }}
          />
          <SimpleGrid cols={2}>
            <TextInput
              label="Gatuadress"
              placeholder="Vägvägen"
              required
              {...form.getInputProps('address_street')}
            />
            <TextInput
              label="Gatunummer"
              placeholder="123"
              required
              {...form.getInputProps('address_number')}
            />
            <TextInput
              label="Postnummer"
              placeholder="123 45"
              required
              {...form.getInputProps('address_zip_code')}
              onChange={(e) => {
                const value = e.target.value;
                const numericValue = value.replace(/\D/g, '');
  
                if (numericValue.length < 4) {
                  form.setFieldValue('address_zip_code', value);
                  return;
                }
  
                form.setFieldValue(
                  'address_zip_code',
                  `${numericValue.substring(0, 3)} ${numericValue.substring(3, 5)}`
                );
  
                const caret = e.target.selectionStart;
                const element = e.target;
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret === 4 ? caret + 1 : caret;
                  element.selectionEnd = caret === 4 ? caret + 1 : caret;
                });
              }}
            />
            <TextInput
              label="Stad"
              placeholder="Staden"
              required
              {...form.getInputProps('address_city')}
            />
          </SimpleGrid>
          <NumberInput
            label="Kundnummer"
            placeholder="0"
            required
            min={1}
            {...form.getInputProps('client_number')}
          />
          <Group position="right" mt="xl">
            <Button onClick={form.onSubmit(submit)} loading={loading}>
              Skapa kund
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  );
};

export default CreateClientModal;

export const CreateClientModalProps: Partial<Omit<ModalProps, 'opened'>> = {
  title: 'Skapa kund',
};
