import { Box, LoadingOverlay, MantineProvider } from '@mantine/core';
import { Route, Routes } from 'react-router-dom';
import './FIREBASE/firebase';
import Login from './pages/auth/Login';
import Dashboard from './pages/home/Dashboard';
import { useContext } from 'react';
import { MadmanContext } from './UTILS/MadmanProvider';
import { ModalsProvider } from '@mantine/modals';
import 'dayjs/locale/sv';

function App() {
  const { user } = useContext(MadmanContext);

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colors: {
          blueberry: [
            '#e3e5ff',
            '#b2b5ff',
            '#7f84ff',
            '#4c54ff',
            '#1a22ff',
            '#0009e6',
            '#0006b4',
            '#000482',
            '#000250',
            '#000021',
          ],
        },
        primaryColor: 'blueberry',
        primaryShade: 4,
        fontFamily: 'Inter, sans-serif',
        fontFamilyMonospace: 'Syne Mono, monospace',
        headings: { fontFamily: 'Syne, sans-serif' },
      }}
    >
      <ModalsProvider>
        <Box
          sx={(theme) => ({
            height: '100%',
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[7]
                : theme.colors.gray[0],
          })}
        >
          {user === false ? (
            <LoadingOverlay visible />
          ) : user ? (
            <Routes>
              <Route path="/" element={<Dashboard />} />
            </Routes>
          ) : (
            <Login />
          )}
        </Box>
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
