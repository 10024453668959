import {
  Box,
  createStyles,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import { find, isArray } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useMemo } from 'react';
import {
  createOCR,
  getTotals,
  isDMInvoiceField,
  isTimestamp,
} from '../../../UTILS/Functions';
import { DeepPartial, DMClient, DMInvoice, DMInvoiceField } from '../../../UTILS/hooks/Types';
import { MadmanContext } from '../../../UTILS/MadmanProvider';

import Logo from '../../../assets/logo_2022.svg';

const useStyles = createStyles((theme, _params, getRef) => ({
  invoice: {
    height: '100%',
    boxShadow: theme.shadows.xl,
    display: 'flex',
    flexDirection: 'column',

    '@media print': {
      boxShadow: 'none',
    },
  },

  logo: {
    width: 80,
    height: 80,
  },

  invoiceMeta: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    columnGap: theme.spacing.lg,
  },

  vatArea: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    columnGap: theme.spacing.lg,
  },
}));

const InvoiceSide = () => {
  const { classes } = useStyles();
  const { wipInvoice, previewInvoice, clients } = useContext(MadmanContext);

  const invoiceData = useMemo(() => {
    if (previewInvoice) return previewInvoice
    if (wipInvoice) return wipInvoice
    return undefined
  }, [wipInvoice, previewInvoice])

  const clientInfo = useMemo<DMClient | undefined>(() => {
    return find(clients, (e) => e.ref.path === invoiceData?.client?.path);
  }, [invoiceData?.client, clients]);

  useEffect(() => {
    let newTitle = '';
    if (!invoiceData?.ocr || !isTimestamp(invoiceData.invoice_date)) {
      newTitle = 'Simons fakturaverktyg';
    } else {
      newTitle = `Faktura ${invoiceData.ocr} från Digital Madman (${moment(
        invoiceData?.invoice_date.toDate()
      ).format('YYYY-MM-DD')})`;
    }
    document.title = newTitle;
  }, [invoiceData?.ocr, invoiceData?.invoice_date]);

  return (
    <Paper className={classes.invoice} radius={0} px="sm" py="lg">
      <Group position="apart" align="flex-end" px={'md'}>
        <img src={Logo} className={classes.logo} alt="" />
        <Box className={classes.invoiceMeta}>
          <Text size="sm" color="dimmed" align="right">
            Fakturanummer:
          </Text>
          <Text size="sm" weight={600} align="right">
            {createOCR(
              invoiceData?.info?.client_number,
              invoiceData?.info?.invoice_number
            )}
          </Text>
          <Text size="sm" color="dimmed" align="right">
            Fakturadatum:
          </Text>
          <Text size="sm" weight={600} align="right">
            {moment(
              isTimestamp(invoiceData?.invoice_date)
                ? invoiceData?.invoice_date.toDate()
                : new Date()
            ).format('YYYY-MM-DD')}
          </Text>
          <Text size="sm" color="dimmed" align="right">
            Förfallodatum:
          </Text>
          <Text size="sm" weight={600} align="right">
            {moment(
              isTimestamp(invoiceData?.due_date)
                ? invoiceData?.due_date.toDate()
                : new Date()
            ).format('YYYY-MM-DD')}
          </Text>
        </Box>
      </Group>

      <SimpleGrid
        cols={2}
        mt={32}
        sx={(theme) => ({
          columnGap: theme.spacing.md,
          rowGap: theme.spacing.xs,
        })}
      >
        <Stack
          spacing={0}
          sx={(theme) => ({
            borderRadius: theme.radius.md,
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors.gray[1],
          })}
          py={'xs'}
          px={'md'}
        >
          <Text color="dimmed" mb={6}>
            Avsändare
          </Text>
          <Text size="sm">{invoiceData?.info?.our_info?.name}</Text>
          <Text size="sm">{invoiceData?.info?.our_info?.org_id}</Text>
          <Text size="sm">
            {invoiceData?.info?.our_info?.address?.street}{' '}
            {invoiceData?.info?.our_info?.address?.number}
          </Text>
          <Text size="sm">
            {invoiceData?.info?.our_info?.address?.zip_code},{' '}
            {invoiceData?.info?.our_info?.address?.city}
          </Text>
        </Stack>
        <Stack
          spacing={0}
          sx={(theme) => ({
            borderRadius: theme.radius.md,
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors.gray[1],
          })}
          py={'xs'}
          px={'md'}
        >
          <Text color="dimmed" mb={6}>
            Mottagare
          </Text>
          <Text size="sm">{clientInfo?.name || '—'}</Text>
          <Text size="sm">{clientInfo?.org_id || '—'}</Text>
          <Text size="sm">
            {clientInfo?.address?.street || '—'}{' '}
            {clientInfo?.address?.number || '—'}
          </Text>
          <Text size="sm">
            {clientInfo?.address?.zip_code || '—'},{' '}
            {clientInfo?.address?.city || '—'}
          </Text>
        </Stack>
      </SimpleGrid>

      <SimpleGrid
        cols={3}
        mt={32}
        sx={(theme) => ({
          columnGap: theme.spacing.md,
          rowGap: theme.spacing.xs,
        })}
        px={'md'}
      >
        <Stack spacing={0}>
          <Text size="xs" color="dimmed">
            Vår referens
          </Text>
          <Text size="sm">{invoiceData?.info?.our_reference}</Text>
        </Stack>
        <Stack spacing={0}>
          <Text size="xs" color="dimmed">
            Er referens
          </Text>
          <Text size="sm">{invoiceData?.info?.client_reference || '—'}</Text>
        </Stack>
        <Stack spacing={0}>
          <Text size="xs" color="dimmed">
            Dröjsmålsränta
          </Text>
          <Text size="sm">{invoiceData?.info?.interest || 0}%</Text>
        </Stack>
        <Stack spacing={0}>
          <Text size="xs" color="dimmed">
            Kundnummer
          </Text>
          <Text size="sm">{invoiceData?.info?.client_number || '—'}</Text>
        </Stack>
        <Stack spacing={0}>
          <Text size="xs" color="dimmed">
            Ordernummer
          </Text>
          <Text size="sm">{invoiceData?.info?.invoice_number || '—'}</Text>
        </Stack>
        {invoiceData?.info?.delivery_terms && (
          <Stack spacing={0}>
            <Text size="xs" color="dimmed">
              Leveransvillkor
            </Text>
            <Text size="sm">{invoiceData.info.delivery_terms}</Text>
          </Stack>
        )}
        {invoiceData?.info?.delivery_method && (
          <Stack spacing={0}>
            <Text size="xs" color="dimmed">
              Leveranssätt
            </Text>
            <Text size="sm">{invoiceData.info.delivery_method}</Text>
          </Stack>
        )}
        {invoiceData?.info?.payment_terms && (
          <Stack spacing={0}>
            <Text size="xs" color="dimmed">
              Betalningsvillkor
            </Text>
            <Text size="sm">{invoiceData.info.payment_terms}</Text>
          </Stack>
        )}
      </SimpleGrid>

      <Table
        mt={24}
        sx={(theme) => ({
          width: 'calc(100% - 12px)',
          marginInline: 6,
        })}
      >
        <thead>
          <tr>
            <th>Beskrivning</th>
            <th>Antal</th>
            <th>Á pris</th>
            <th
              style={{
                textAlign: 'right',
              }}
            >
              Belopp
            </th>
          </tr>
        </thead>
        <tbody>
          {isArray(invoiceData?.fields) &&
            invoiceData?.fields.flatMap((field, index) => {
              if (!isDMInvoiceField(field)) return [];
              return <FieldRow field={field} key={index} />;
            })}
        </tbody>
      </Table>

      <TotalSection invoiceData={invoiceData} />

      <FooterSection />
    </Paper>
  );
};

export default InvoiceSide;

const FieldRow = ({ field }: { field: DMInvoiceField }) => {
  const formattedAmount = useMemo(() => {
    switch (field.unit) {
      case 'h':
        let hours = field.amount.toFixed(2).split('.')[0];
        let minutes = parseInt(field.amount.toFixed(2).split('.')[1]);
        if (minutes >= 60) minutes = 59;
        return hours + 'h ' + minutes + 'm';
      case 'st':
        return field.amount.toFixed(2) + ' st';
    }
  }, [field.amount, field.unit]);

  const decimalAmount = useMemo(() => {
    switch (field.unit) {
      case 'h':
        let hours = field.amount.toFixed(2).split('.')[0];
        let minutes = parseInt(field.amount.toFixed(2).split('.')[1]);
        if (minutes >= 60) minutes = 59;

        let minuteFraction = Math.round((minutes / 60) * 100).toString();
        if (minuteFraction.length === 1) minuteFraction = '0' + minuteFraction;

        return parseFloat(`${hours}.${minuteFraction}`);
      case 'st':
        return field.amount;
    }
  }, [field.amount, field.unit]);

  return (
    <tr>
      <td>{field.title}</td>
      <td>{formattedAmount}</td>
      <td>{field.price.toLocaleString('sv')} kr</td>
      <td
        style={{
          textAlign: 'right',
        }}
      >
        {parseFloat((decimalAmount * field.price).toFixed(2)).toLocaleString(
          'sv'
        )}{' '}
        kr
      </td>
    </tr>
  );
};

const TotalSection = ({invoiceData} : {invoiceData: DeepPartial<DMInvoice> | undefined}) => {
  const { classes } = useStyles();

  const values = useMemo<{
    vat25: number;
    vat12: number;
    vat6: number;
    vat0: number;
    service_fee: number;
    before_vat: number;
    total_vat: number;
    rounding: number;
    total: number;
  }>(() => {
    return getTotals(invoiceData?.fields);
  }, [invoiceData?.fields]);

  return (
    <>
      <Box mt={'auto'}></Box>
      <Group
        noWrap
        position="apart"
        mt={32}
        sx={(theme) => ({
          borderRadius: theme.radius.md,
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[5]
              : theme.colors.gray[1],
        })}
        py={'xs'}
        px={'md'}
      >
        <Box className={classes.vatArea}>
          <Text size="sm" color="dimmed">
            Moms 25%:
          </Text>
          <Text size="sm" align="right">
            {values.vat25.toLocaleString('sv')} kr
          </Text>

          <Text size="sm" color="dimmed">
            Moms 12%:
          </Text>
          <Text size="sm" align="right">
            {values.vat12.toLocaleString('sv')} kr
          </Text>

          <Text size="sm" color="dimmed">
            Moms 6%:
          </Text>
          <Text size="sm" align="right">
            {values.vat6.toLocaleString('sv')} kr
          </Text>
        </Box>
        <Box className={classes.vatArea}>
          <Text size="sm" color="dimmed">
            Momsfritt:
          </Text>
          <Text size="sm" align="right">
            {values.vat0.toLocaleString('sv')} kr
          </Text>

          <Text size="sm" color="dimmed">
            Expeditionsavgift:
          </Text>
          <Text size="sm" align="right">
            {values.service_fee.toLocaleString('sv')} kr
          </Text>

          <Text size="sm" color="dimmed">
            Belopp före moms:
          </Text>
          <Text size="sm" align="right">
            {values.before_vat.toLocaleString('sv')} kr
          </Text>
        </Box>
        <Box className={classes.vatArea}>
          <Text size="sm" color="dimmed">
            Total moms:
          </Text>
          <Text size="sm" align="right">
            {values.total_vat.toLocaleString('sv')} kr
          </Text>

          <Text size="sm" color="dimmed">
            Öresutjämning:
          </Text>
          <Text size="sm" align="right">
            {values.rounding.toLocaleString('sv')} kr
          </Text>

          <Text size="sm" weight={700}>
            Att betala:
          </Text>
          <Text size="sm" align="right" weight={700}>
            {values.total.toLocaleString('sv')} kr
          </Text>
        </Box>
      </Group>
    </>
  );
};

const FooterSection = () => {
  const { classes } = useStyles();
  const { ourInvoiceInfo } = useContext(MadmanContext);

  return (
    <>
      <Group noWrap position="apart" mt={32} py={'xs'} px={'md'}>
        <Box className={classes.vatArea}>
          <Text size="xs" color="dimmed">
            Tel. nr:
          </Text>
          <Text size="xs">{ourInvoiceInfo?.footer.phone}</Text>

          <Text size="xs" color="dimmed">
            E-post:
          </Text>
          <Text size="xs">{ourInvoiceInfo?.footer.email}</Text>

          <Text size="xs" color="dimmed">
            Webb:
          </Text>
          <Text size="xs">{ourInvoiceInfo?.footer.web}</Text>
        </Box>
        <Box className={classes.vatArea}>
          <Text size="xs" color="dimmed">
            Org. nr:
          </Text>
          <Text size="xs">{ourInvoiceInfo?.footer.org_id}</Text>

          <Text size="xs" color="dimmed">
            Momsnr:
          </Text>
          <Text size="xs">{ourInvoiceInfo?.footer.vat_number}</Text>

          <Text size="xs" color="dimmed">
            Bankgiro:
          </Text>
          <Text size="xs">{ourInvoiceInfo?.footer.bankgiro}</Text>
        </Box>
        <Box className={classes.vatArea}>
          <Text size="xs" color="dimmed">
            Plusgiro:
          </Text>
          <Text size="xs">{ourInvoiceInfo?.footer.plusgiro}</Text>

          <Text size="xs" color="dimmed">
            Swift/Bic:
          </Text>
          <Text size="xs">{ourInvoiceInfo?.footer.swift}</Text>

          <Text size="xs" color="dimmed">
            IBAN:
          </Text>
          <Text size="xs">{ourInvoiceInfo?.footer.iban}</Text>
        </Box>
      </Group>
    </>
  );
};
