import { User, onAuthStateChanged, getAuth } from 'firebase/auth';
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import React, { createContext, useState } from 'react';
import {
  DeepPartial,
  DMClient,
  DMInvoice,
  DMOurInvoiceValues,
} from './hooks/Types';
import useStateEffect from './hooks/useStateEffect';

const MadmanProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useStateEffect<User | null | false>(
    false,
    () => {
      const listener = onAuthStateChanged(
        getAuth(),
        (credential) => {
          setUser(credential);
        },
        (error) => console.error(error)
      );

      return () => listener();
    },
    []
  );

  const [clients, setClients] = useStateEffect<DMClient[]>(
    [],
    () => {
      if (!user) return;

      const listener = onSnapshot(
        query(
          collection(getFirestore(), 'clients'),
          orderBy('client_number', 'asc')
        ),
        (snapshot) => {
          setClients(
            snapshot.docs.map(
              (snapshot) =>
                ({
                  ...snapshot.data(),
                  ref: snapshot.ref,
                } as DMClient)
            )
          );
        },
        (err) => console.error(err)
      );

      return () => listener();
    },
    [user]
  );

  const [wipInvoice, setWipInvoice] = useState<DeepPartial<DMInvoice>>();

  const [previewInvoice, setPreviewInvoice] = useState<DMInvoice | undefined>();

  const [ourInvoiceInfo, setOurInvoiceInfo] = useStateEffect<
    DMOurInvoiceValues | undefined
  >(
    undefined,
    () => {
      if (!user) return;

      const listener = onSnapshot(
        doc(getFirestore(), 'platform', 'invoice_values'),
        (snapshot) => {
          setOurInvoiceInfo({
            ...snapshot.data(),
          } as DMOurInvoiceValues);
        }
      );

      return () => listener();
    },
    [user]
  );

  return (
    <MadmanContext.Provider
      value={{
        user,
        setUser,
        clients,
        setClients,
        wipInvoice,
        setWipInvoice,
        previewInvoice,
        setPreviewInvoice,
        ourInvoiceInfo,
        setOurInvoiceInfo,
      }}
    >
      {children}
    </MadmanContext.Provider>
  );
};

export default MadmanProvider;

export interface MadmanProps {
  user: User | null | false;
  clients: DMClient[];
  wipInvoice: DeepPartial<DMInvoice> | undefined;
  previewInvoice: DMInvoice | undefined;
  ourInvoiceInfo: DMOurInvoiceValues | undefined;
}

export interface GlobalMadman extends MadmanProps {
  setUser: React.Dispatch<React.SetStateAction<User | null | false>>;
  setClients: React.Dispatch<React.SetStateAction<DMClient[]>>;
  setWipInvoice: React.Dispatch<
    React.SetStateAction<DeepPartial<DMInvoice> | undefined>
  >;
  setPreviewInvoice: React.Dispatch<
    React.SetStateAction<DMInvoice | undefined>
  >;
  setOurInvoiceInfo: React.Dispatch<
    React.SetStateAction<DMOurInvoiceValues | undefined>
  >;
}

export const initialMadman: MadmanProps = {
  user: false,
  clients: [],
  wipInvoice: undefined,
  previewInvoice: undefined,
  ourInvoiceInfo: undefined,
};

export const MadmanContext = createContext<GlobalMadman>({
  ...initialMadman,
  setClients: () => {},
  setUser: () => {},
  setWipInvoice: () => {},
  setPreviewInvoice: () => {},
  setOurInvoiceInfo: () => {},
});
