import {
  Button,
  Card,
  Center,
  createStyles,
  Group,
  PasswordInput,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';

const useStyles = createStyles((theme, _params, getRef) => ({
  card: {
    width: 360,
  },

  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,

    '&.noPadding': {
      padding: 0,
    },
  },
}));

const Login = () => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : 'Ogiltig e-postadress',
      password: (value) => (value.length > 4 ? null : 'Ogiltigt lösenord'),
    },
  });

  const [loading, setLoading] = useState(false);
  const submit = async (values: { email: string; password: string }) => {
    if (loading) return;
    setLoading(true);

    await signInWithEmailAndPassword(
      getAuth(),
      values.email,
      values.password
    ).catch((err) => console.error(err));

    setLoading(false);
  };

  return (
    <Group
      sx={(theme) => ({
        height: '100%',
      })}
    >
      <Center
        sx={(theme) => ({
          height: '100%',
          width: theme.breakpoints.sm,
        })}
      >
        <Card withBorder shadow={'md'} className={classes.card}>
          <Card.Section className={classes.section}>
            <Title order={4}>Logga in</Title>
          </Card.Section>
          <Card.Section className={classes.section}>
            <form onSubmit={form.onSubmit(submit)}>
              <Stack spacing={'xs'}>
                <TextInput
                  required
                  label="E-post"
                  placeholder="din@epost.se"
                  {...form.getInputProps('email')}
                />
                <PasswordInput
                  required
                  label="Lösenord"
                  placeholder="********"
                  {...form.getInputProps('password')}
                />
              </Stack>
            </form>
          </Card.Section>
          <Card.Section p={'sm'}>
            <Group position="right">
              <Button loading={loading} onClick={form.onSubmit(submit)}>
                Logga in
              </Button>
            </Group>
          </Card.Section>
        </Card>
      </Center>
    </Group>
  );
};

export default Login;
