import { Timestamp } from 'firebase/firestore';
import { isArray } from 'lodash';
import { DeepPartial, DMClient, DMInvoiceField } from './hooks/Types';

export const isDMClient = (value: any): value is DMClient => {
  if (value === null || value === undefined) return false;
  return value['client_number'] !== undefined;
};

export const isTimestamp = (value: any): value is Timestamp => {
  if (value === null || value === undefined) return false;
  return value['seconds'] !== undefined;
};

export const isDMInvoiceField = (value: any): value is DMInvoiceField => {
  if (value === null || value === undefined) return false;
  return value['title'] !== undefined;
};

export const createOCR = (client_number?: number, invoice_number?: number) => {
  if (!client_number || !invoice_number) {
    return '9999';
  }

  let client_string = client_number.toString();
  let invoice_string = invoice_number.toString();

  switch (client_string.length) {
    case 1:
      client_string = '00' + client_string;
      break;
    case 2:
      client_string = '0' + client_string;
      break;
    default:
      break;
  }

  switch (invoice_string.length) {
    case 1:
      invoice_string = '00' + invoice_string;
      break;
    case 2:
      invoice_string = '0' + invoice_string;
      break;
    default:
      break;
  }

  return "9999" + client_string + invoice_string
}

export const getTotals = (fields?: (DeepPartial<DMInvoiceField> | undefined)[] | undefined) => {
    let tempValues = {
        vat25: 0,
        vat12: 0,
        vat6: 0,
        vat0: 0,
        service_fee: 0,
        before_vat: 0,
        total_vat: 0,
        rounding: 0,
        total: 0,
      };
      if (!isArray(fields)) return tempValues;
  
      const getDecimalAmount = (field: DMInvoiceField) => {
        switch (field.unit) {
          case 'h':
            let hours = field.amount.toFixed(2).split('.')[0];
            let minutes = parseInt(field.amount.toFixed(2).split('.')[1]);
            if (minutes >= 60) minutes = 59;
  
            let minuteFraction = Math.round((minutes / 60) * 100).toString();
            if (minuteFraction.length === 1)
              minuteFraction = '0' + minuteFraction;
  
            return parseFloat(`${hours}.${minuteFraction}`);
          case 'st':
            return field.amount;
        }
      };
  
      fields.forEach((field) => {
        if (!isDMInvoiceField(field)) return;
        switch (field.vat) {
          case 25:
            tempValues.vat25 += getDecimalAmount(field) * field.price;
            break;
          case 12:
            tempValues.vat12 += getDecimalAmount(field) * field.price;
            break;
          case 6:
            tempValues.vat6 += getDecimalAmount(field) * field.price;
            break;
          case 0:
            tempValues.vat0 += getDecimalAmount(field) * field.price;
            break;
        }
      });
  
      tempValues.before_vat =
        tempValues.vat25 + tempValues.vat12 + tempValues.vat6 + tempValues.vat0;
  
      tempValues.total_vat =
        tempValues.vat25 * 0.25 +
        tempValues.vat12 * 0.12 +
        tempValues.vat6 * 0.06;
  
      tempValues.total = Math.floor(tempValues.before_vat + tempValues.total_vat);
  
      tempValues.rounding =
        tempValues.total - (tempValues.before_vat + tempValues.total_vat);
  
      tempValues = {
        vat25: parseFloat(tempValues.vat25.toFixed(2)),
        vat12: parseFloat(tempValues.vat12.toFixed(2)),
        vat6: parseFloat(tempValues.vat6.toFixed(2)),
        vat0: parseFloat(tempValues.vat0.toFixed(2)),
        service_fee: parseFloat(tempValues.service_fee.toFixed(2)),
        before_vat: parseFloat(tempValues.before_vat.toFixed(2)),
        total_vat: parseFloat(tempValues.total_vat.toFixed(2)),
        rounding: parseFloat(tempValues.rounding.toFixed(2)),
        total: parseFloat(tempValues.total.toFixed(2)),
      };
  
      return tempValues;
}