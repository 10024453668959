import React, { useEffect, useState } from 'react';

function useStateEffect<S>(
  initialValue: S,
  effect: React.EffectCallback,
  deps?: React.DependencyList | undefined
) {
  const [value, setValue] = useState<S>(initialValue);

  useEffect(effect, deps);

  return [value, setValue] as const;
}

export default useStateEffect;
