import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  createStyles,
  Group,
  Popover,
  ScrollArea,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import {
  collection,
  deleteDoc,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { Edit, Eye, Trash } from 'tabler-icons-react';
import { getTotals } from '../../../UTILS/Functions';
import { DMClient, DMInvoice } from '../../../UTILS/hooks/Types';
import useStateEffect from '../../../UTILS/hooks/useStateEffect';
import { MadmanContext } from '../../../UTILS/MadmanProvider';

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,

    '&.noPadding': {
      padding: 0,
    },
  },

  main: {
    height: '100%',
  },
}));

const BrowseInvoices = ({
  setActiveTab,
}: {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { classes } = useStyles();
  const { clients } = useContext(MadmanContext);

  return (
    <Box className={classes.main}>
      <ScrollArea
        style={{
          height: 'calc(100vh - 50px)',
          transform: 'translate3d(0,0,0)',
        }}
      >
        <Stack p={'sm'}>
          {clients.map((client) => {
            return (
              <ClientRow
                key={client.ref.path}
                client={client}
                setActiveTab={setActiveTab}
              />
            );
          })}
        </Stack>
      </ScrollArea>
    </Box>
  );
};

export default BrowseInvoices;

const ClientRow = ({
  client,
  setActiveTab,
}: {
  client: DMClient;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { classes } = useStyles();

  const [invoices, setInvoices] = useStateEffect<DMInvoice[]>(
    [],
    () => {
      const listener = onSnapshot(
        collection(getFirestore(), client.ref.path, 'invoices'),
        (snapshot) => {
          setInvoices(
            snapshot.docs.map(
              (snapshot) =>
                ({
                  ...snapshot.data(),
                  ref: snapshot.ref,
                } as DMInvoice)
            )
          );
        }
      );

      return () => listener();
    },
    [client]
  );

  return (
    <Card withBorder>
      <Card.Section className={classes.section}>
        <Group position="apart" align={'baseline'}>
          <Title order={4}>{client.name}</Title>
          <Text color="dimmed">Kundnummer: {client.client_number}</Text>
        </Group>
      </Card.Section>
      <Card.Section>
        <Table>
          <thead>
            <tr>
              <th>Fakturanummer</th>
              <th>Totalbelopp</th>
              <th>Fakturadatum</th>
              <th>Förfallodatum</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => {
              return (
                <InvoiceRow
                  key={invoice.ocr}
                  invoice={invoice}
                  setActiveTab={setActiveTab}
                />
              );
            })}
          </tbody>
        </Table>
        {!invoices.length && (
          <Center p={'md'}>
            <Text color={'dimmed'} size="sm" weight={500}>
              Inget här
            </Text>
          </Center>
        )}
      </Card.Section>
    </Card>
  );
};

const InvoiceRow = ({
  invoice,
  setActiveTab,
}: {
  invoice: DMInvoice;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { setWipInvoice, setPreviewInvoice } = useContext(MadmanContext);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  return (
    <tr key={invoice.ocr}>
      <td>{invoice.ocr}</td>
      <td>{getTotals(invoice.fields).total.toLocaleString()} kr</td>
      <td>{moment(invoice.invoice_date.toDate()).format('YYYY-MM-DD')}</td>
      <td>{moment(invoice.due_date.toDate()).format('YYYY-MM-DD')}</td>
      <td>
        <Group spacing={4}>
          <ActionIcon
            onClick={() => {
              setPreviewInvoice(invoice);
            }}
          >
            <Eye size={16} />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              setPreviewInvoice(undefined)
              setWipInvoice(invoice);
              setActiveTab(0);
            }}
          >
            <Edit size={16} />
          </ActionIcon>
          <Popover
            opened={confirmDeleteOpen}
            target={
              <ActionIcon
                color={'red'}
                onClick={() => setConfirmDeleteOpen((p) => !p)}
              >
                <Trash size={16} />
              </ActionIcon>
            }
            withArrow
            position="bottom"
            placement="center"
            spacing={'xs'}
            onClose={() => setConfirmDeleteOpen(false)}
          >
            <Button
              color="red"
              variant="light"
              onClick={() => {
                if (!invoice.ref) return;
                deleteDoc(invoice.ref).then(() => {
                  setWipInvoice(undefined);
                });
              }}
            >
              Ta bort
            </Button>
          </Popover>
        </Group>
      </td>
    </tr>
  );
};
