import { createStyles, Tabs } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { FilePlus, Edit } from 'tabler-icons-react';
import BrowseInvoices from './BrowseInvoices';
import CreateInvoice from './CreateInvoice';
import { v4 as uuid } from 'uuid';
import { MadmanContext } from '../../../UTILS/MadmanProvider';

const useStyles = createStyles((theme, _params, getRef) => ({
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '@media print': {
      display: 'none',
    },

    '.mantine-Tabs-body': {
      flex: 1,
    },
  },
}));

const FormSide = () => {
  const { classes } = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [createInvoiceKey, setCreateInvoiceKey] = useState(uuid())
  const {setPreviewInvoice} = useContext(MadmanContext)

  useEffect(() => {
    if (activeTab === 0) setPreviewInvoice(undefined)
  }, [activeTab])
  

  return (
    <Tabs
      active={activeTab}
      onTabChange={setActiveTab}
      className={classes.main}
    >
      <Tabs.Tab label="Skapa faktura" icon={<FilePlus size={14} />}>
        <CreateInvoice key={createInvoiceKey} setCreateInvoiceKey={setCreateInvoiceKey} />
      </Tabs.Tab>
      <Tabs.Tab label="Ändra faktura" icon={<Edit size={14} />}>
        <BrowseInvoices setActiveTab={setActiveTab} />
      </Tabs.Tab>
    </Tabs>
  );
};

export default FormSide;
